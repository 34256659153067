<template>
  <v-container>
    <v-row class="title-sticky">
      <v-col cols="12" lg="12">
        <TitleSection
          :title="ticket ? getTitle()[lang] : defaultTitle[lang]"
          btnBack
          class="pl-0"
        ></TitleSection>
      </v-col>
    </v-row>

    <v-row v-if="ticket">
      <v-col cols="12" lg="12">
        <v-card>
          <v-card-title>
            <div>
              <v-icon left> tag </v-icon>
              <span class="text-h6 fw-600">{{ ticket.IDCOMPRA }}</span>
            </div>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <img
                  draggable="false"
                  :src="getImageStatusURL()"
                  height="70"
                  alt=""
                />
                <p
                  v-if="ticket.ESTADO"
                  :class="`${getClassColorStatus()} text-medium fw-500 mt-4`"
                >
                  {{ getPurchaseStatusMessage()[lang] }}
                </p>
                <p v-if="ticket.ESTADO == 4">
                  <small>{{ getPurchaseStatusMessage().subtitle[lang] }}</small>
                </p>
              </v-col>

              <v-col
                v-for="(item, idx) in getGeneralInformation(ticket, lang)"
                :key="idx + 1500 + baseIndex"
                cols="12"
                lg="12"
                md="12"
                sm="12"
              >
                <h4
                  v-if="item.type == 'title'"
                  class="text-blue subtitle-ticket fw-700"
                >
                  {{ item.title[lang] }}
                </h4>
                <div v-if="item.type == 'chip-title'" class="section-title">
                  <h4>{{ item.title[lang] }}</h4>
                </div>
                <div
                  v-if="item.type == 'section'"
                  class="section-detail"
                  v-html="item.value"
                ></div>
                <p
                  v-if="
                    item.type != 'title' &&
                      item.type != 'section' &&
                      item.type != 'chip-title'
                  "
                  class="text-detail text-medium fw-500 mb-2"
                >
                  <strong v-show="item.title && item.title[lang]"
                    >{{ item.title[lang] }}:</strong
                  >
                  <span v-if="item.type == 'text'">{{ item.value }}</span>
                  <span v-if="item.type == 'html'" v-html="item.value"></span>
                  <a v-if="item.type == 'link'" :href="item.value">{{
                    item.linkTitle[lang]
                  }}</a>
                </p>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="my-6 mb-5 d-flex justify-content-center">
            <v-btn
              v-if="ticket.HABILITARSORTEO == '0' && ticket.ESTADO == '2'"
              color="primary"
              class="mb-5 px-5 readable"
              @click="getPurchasePDF"
              :loading="loadingPDF"
              :disabled="loadingPDF"
              rounded
              depressed
              dark
              :title="titleDownload[lang]"
              :aria-label="titleDownload[lang]"
            >
              <span>{{ labels.btnDownload[lang] }}</span>
              <v-icon>file_download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Calendar -->
      <v-dialog
        ref="dialogDate"
        v-model="dialogCalendar"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <v-date-picker
          v-model="date"
          scrollable
          :allowed-dates="getAllowedDates"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialogCalendar = false">
            {{ labels.btnCancel[lang] }}
          </v-btn>
          <v-btn
            :disabled="loadingCalendar || loadingBtn"
            :loading="loadingBtn"
            text
            class="ml-1"
            color="primary"
            rounded
            depressed
            @click="saveDate(date)"
          >
            {{ labels.btnSaveCalendar[lang] }}
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-dialog v-model="dialogHorario" width="400" persistent>
        <v-card class="mb-12 elevation-0" width="400">
          <v-card-title>
            <div class="d-flex flex-column">
              <div class="title-card">{{ labels.titleSchedule[lang] }}</div>
              <p class="subtitle-card">{{ labels.subtitleSchedule[lang] }}</p>
            </div>
          </v-card-title>
          <v-list dense>
            <v-list-item color="tertiary" class="bg-tertiary" dark>
              <v-list-item-content>
                <v-list-item-title class="fw-600">{{
                  labels.tableSchedule.col1[lang]
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div class="aforo-info d-flex">
                  <span class="title-type">
                    {{ labels.tableSchedule.col2[lang] }}
                  </span>
                  <span class="divider">/</span>
                  <span class="title-type">
                    {{ labels.tableSchedule.col3[lang] }}
                  </span>
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-list-item-group
              :mandatory="payload.schedule ? true : false"
              color="primary"
              v-model="payload.schedule"
            >
              <template v-for="(item, i) in schedules">
                <v-list-item
                  :key="baseIndex + 15896 + i"
                  :value="`${i + 1}-${item.HORAINICIO}-${item.HORAFIN}`"
                  :disabled="item.CANTIDAD == 0 || item.ENABLED == '0'"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.HORAINICIO }} -
                      {{ item.HORAFIN }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="aforo-info d-flex">
                      <span class="content">
                        {{ item.CANTIDAD }}
                      </span>
                      <span class="divider">/</span>
                      <span class="content">
                        {{ item.AFORO }}
                      </span>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dialogHorario = false">
              {{ labels.btnCancel[lang] }}
            </v-btn>
            <v-btn
              :disabled="loadingBtn || !payload.schedule"
              :loading="loadingBtn"
              class="ml-1"
              color="primary"
              rounded
              depressed
              text
              @click="saveWithSchedule(true)"
            >
              {{ labels.btnSave[lang] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row v-else>
      <v-col
        v-if="!$store.state.loader"
        cols="12"
        lg="12"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <img
          draggable="false"
          :src="getImageStatusURL(true)"
          height="70"
          alt=""
        />
        <p class="text-center my-10 text-medium fw-400">
          {{ noTicketFound[lang] }} {{ $route.params.purchaseId }}
        </p>
      </v-col>
    </v-row>

    <template v-if="!user && !ticket">
      <div class="text-center my-10 text-medium fw-400 readable">
        {{ noUserMsg[lang] }}
      </div>
    </template>
  </v-container>
</template>

<script>
import TitleSection from "@/components/titles/TitleSection";
import { imgService } from "@/libs/media";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  components: {
    TitleSection,
  },
  data() {
    return {
      ticket: null,
      error: false,
      loadingPDF: false,
      defaultTitle: {
        en: "Go back",
        es: "Regresar",
      },
      titlePurchase: {
        en: "Summary of purchase",
        es: "Resumen de la compra",
      },
      titleRegistry: {
        en: "Record Summary",
        es: "Resumen del registro",
      },
      titleDownload: {
        en: "Download",
        es: "Descargar",
      },
      labels: {
        visitDate: {
          en: "Visit date",
          es: "Fecha de visita",
        },
        amount: {
          en: "Total",
          es: "Importe total",
        },
        purchaseNumber: {
          en: "Purchase number",
          es: "Número de compra",
        },
        btn: {
          en: "See detail",
          es: "Ver detalle",
        },
        defaultMessage: {
          en: "",
          es: "",
        },
        btnCancel: {
          en: "Cancel",
          es: "Cancelar",
        },
        btnSaveCalendar: {
          en: "Save date",
          es: "Guardar Fecha",
        },
        btnSave: {
          en: "Save",
          es: "Guardar",
        },
        titleSchedule: {
          es: "Horarios y aforo",
          en: "Hours and capacity",
        },
        subtitleSchedule: {
          en: "Choose your preferred time",
          es: "Elige tu horario de preferencia",
        },
        btnDownload: {
          en: "Download",
          es: "Descargar",
        },
        tableSchedule: {
          col1: {
            en: "Schedule",
            es: "Horario",
          },
          col2: {
            en: "Available capacity",
            es: "Capacidad disponible",
          },
          col3: {
            en: "Total capacity",
            es: "Capacidad total",
          },
        },
        errorMessage: {
          en: "Your purchase could not be processed",
          es: "Tu compra no se pudo procesar",
        },
        successMessage: {
          en: "Your purchase was successful",
          es: "Tu compra se realizó con éxito",
        },
        cancelMessage: {
          en: "Your purchase has been canceled",
          es: "Tu compra ha sido cancelada",
        },
      },
      noUserMsg: {
        es: "Necesitas iniciar sesión para ver los detalles de compra/registro",
        en: "You need to log in to view purchase/registration details",
      },
      noTicketFound: {
        es: "No se encontraron los resultados del número de compra",
        en: "No results found for purchase number",
      },
      changeDate: false,
      dialogCalendar: false,
      dialogHorario: false,
      date: null,
      dateSelected: "",
      purchaseId: null,
      generalInformation: [],
      payload: {
        schedule: null,
      },
      globalAforo: false,
      loadingCalendar: false,
      loadingBtn: false,
      buttonSave: {
        en: "Save",
        es: "Guardar",
      },
      schedules: [],
      articles: [],
      baseIndex: 258655465,
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      user: "getSessionUser",
      shoppingList: "getMyShoppingsList",
      haveAditionalProccess: "haveAditionalProccess",
      accTools: "getAccesibilityTool",
    }),
  },
  watch: {
    ticket(val) {
      if (val) {
        this.accTools.restart(this.lang);
      }
    },
    lang(oldVal) {
      this.loadDataPurchase();
    },
    shoppingList(val) {
      if (val.length > 0) {
        this.loadDataPurchase();
      }
    },
    user(val) {
      const { visitDate, user } = this.$route.query;
      if (!this.user && !user && !visitDate) {
        this.ticket = null;
      }
    },
    changeDate(bool) {
      try {
        if (bool) {
          setTimeout(() => {
            const btn = document.getElementById("btnOpenCalendar");
            btn.addEventListener("click", this.openDialogCalendar);
          }, 1500);
        } else {
          if (document.getElementById("btnOpenCalendar")) {
            document
              .getElementById("btnOpenCalendar")
              .removeEventListener("click", this.openDialogCalendar);
          }
        }
      } catch (error) {}
    },
  },
  async created() {
    try {
      const { parkId } = this.$route.params;
      if (Number(parkId)) {
        this.$store.commit("activeLoader", true);
        await this.$store.dispatch("getParkByDomain", Number(parkId));
        this.$store.commit("activeLoader", false);
      }
    } catch (error) {
      this.$store.commit("activeLoader", false);
    }
    await this.loadDataPurchase();
  },
  /* async mounted() {
    this.checkNoUserToDownload();
  }, */
  beforeDestroy() {
    this.destroyAdvance();
  },
  methods: {
    ...mapActions({
      fetchSchedules: "fetchSchedules",
      getAditionalProccess: "getAditionalProccess",
      changeDateToVisit: "changeDateToVisit",
      generateSnackbarError: "generateSnackbarError",
      getSchedulesDependAditionalProccess:
        "getSchedulesDependAditionalProccess",
      validateAllBeforeSave: "validateAllBeforeSave",
      destroyAdvance: "destroyAdvance",
      // openPDFOnNewWindow: "openPDFOnNewWindow",
      // downloadPDF: "downloadPDF",
      generateMessageStatus: "generateMessageStatus",
    }),
    async checkNoUserToDownload() {
      if (this.user) return;
      const { purchaseId, parkId } = this.$route.params;
      const { user } = this.$route.query;
      if (!purchaseId) return;
      const res = await this.$store.dispatch("fetchPurchaseDetail", {
        purchaseId,
        sendMail: 2,
        parkId,
        noUser: true,
      });
      if (!res) return;

      const { CORREO, PDF, ESTADO } = res;

      if (String(CORREO).toLowerCase() != String(user).toLowerCase()) return;
      if (ESTADO != "2") {
        this.generateMessageStatus({
          title: {
            es: "Tu compra no se pudo procesar",
            en: "Your purchase could not be processed",
          },
          html: {
            es: `<p class="text-center"><span > Estimado usuario, verifique que tenga activo la opción de compras en línea en su tarjeta. <br> </span> </p>`,
            en: `<p class="text-center"><span > Dear user, verify that you have the online shopping option active on your card. </span> </p>`,
          },
          type: "error",
          confirmButtonText: {
            en: "Ok",
            es: "Aceptar",
          },
          footer: {
            es: `<p class="text-center"><span > <strong>Número de compra: </strong> ${purchaseId}</span> </p>`,
            en: `<p class="text-center"><span > <strong>Purchase number: </strong> ${purchaseId}</span> </p>`,
          },
          allowOutsideClick: false,
          callback: () => {
            this.$router.push("/").catch((err) => {});
          },
        });
        return;
      }
      this.generateMessageStatus({
        title: {
          en: "Your purchase was successful",
          es: "Tu compra se realizó con éxito",
        },
        html: {
          es: `<p class="text-center"><span > Estimado usuario, su compra se concretó de manera exitosa. <br>Por favor presione en aceptar para descargar el documento PDF de su compra. </span> </p>`,
          en: `<p class="text-center"><span > Dear user, your purchase was successful. <br>Please click accept to download the PDF document of your purchase. </span> </p>`,
        },
        type: "success",
        confirmButtonText: {
          en: "Ok",
          es: "Aceptar",
        },
        footer: {
          es: `<p class="text-center"><span > <strong>Número de compra: </strong> ${purchaseId}</span> </p>`,
          en: `<p class="text-center"><span > <strong>Purchase number: </strong> ${purchaseId}</span> </p>`,
        },
        allowOutsideClick: false,
        callback: async (result) => {
          if (result.isConfirmed) {
            // await this.downloadPDF({ pdfId: PDF, purchaseId });
            await this.$store.dispatch("downloadPurchaseFile", {
              idPdf: PDF,
              idPark: this.ticket.IDPARQUE,
              idCompra: purchaseId,
            });
            this.$router.push("/").catch((err) => {});
          }
        },
      });
    },
    async loadDates() {
      this.destroyAdvance();
      this.loadingCalendar = true;
      let list = [];
      if (this.ticket.IDEVENTO == "0") {
        list = await this.$store.dispatch("getCalendarList");
      } else {
        list = await this.$store.dispatch(
          "getEventsDate",
          this.ticket.IDEVENTO
        );
      }
      this.loadingCalendar = false;
      if (!list) return;
      this.schedules = list;
    },
    async saveDate(date) {
      this.dateSelected = date;
      const dateSelected = this.formatDateToISO(date);

      if (this.haveAditionalProccess) {
        let articleId = 0;
        if (this.ticket.COMBINAR == "0") {
          // no permite combinar, quiere decir que solo hay un artículo
          articleId = this.ticket.IDARTICULO;
        }
        this.loadingBtn = true;
        const res = await this.getSchedulesDependAditionalProccess({
          IDARTICULO: articleId,
          IDCATEGORIA: this.ticket.IDCATEGORIA,
          FECHAVISITA: dateSelected,
        });

        this.loadingBtn = false;

        if (res.error || !res.schedules) {
          // error
          this.generateSnackbarError({
            en: "Process configuration error",
            es: "Error de configuración de proceso",
          });
          return;
        }

        this.schedules = res.schedules;
        this.globalAforo = false;

        if (res.noCategory) {
          this.globalAforo = true;
        }

        if (res.schedules.length == 1 && res.onlyAforo) {
          const validated = await this.validateAllBeforeSave({
            controlStock: this.ticket.CONTROLSTOCK,
            articles: this.ticket.TICKETS,
            visitDate: dateSelected,
            schedule: res.schedules[0],
            routeId: 0,
            categoryId: res.noCategory ? 0 : this.ticket.IDCATEGORIA,
            iOption: 2,
          });
          /* const haveSpace = await this.$store.dispatch(
            "validateAforo",
            {
              ...res.schedules[0],
            }
          ); */

          if (validated) {
            // Guardar automaticamente
            const defaultSchedule = res.schedules[0];
            this.payload.schedule = `1-${defaultSchedule.HORAINICIO}-${defaultSchedule.HORAFIN}`;
            this.saveWithSchedule(false);
            this.dialogCalendar = false;
            return;
          } else {
            // no hay aforo disponible
            this.generateSnackbarError({
              en: "There is no capacity available",
              es: "No hay aforo disponible",
            });
            this.dialogCalendar = false;
            return;
          }
        }

        // Abre listado de horarios
        this.dialogCalendar = false;
        this.dialogHorario = true;
      } else {
        await this.saveWithoutSchedule();
      }
    },
    formatDateToISO(date) {
      const arr = date.split("-");
      return `${arr[2]}/${arr[1]}/${arr[0]}`;
    },
    getAllowedDates(val) {
      const founded = this.schedules.findIndex(
        (it) => it.FECHA == this.formatDateToISO(val) && it.ENABLED == "1"
      );
      return founded !== -1;
    },
    async saveWithoutSchedule() {
      const dateSelected = this.formatDateToISO(this.dateSelected);
      this.loadingBtn = true;
      await this.changeDateToVisit({
        ...this.ticket,
        NUEVAFECHAVISITA: dateSelected,
        HORARIOINICIO: "",
        HORARIOFIN: "",
      });
      await this.loadDataPurchase(1);
      this.loadingBtn = false;
    },
    async saveWithSchedule(validation) {
      const dateSelected = this.formatDateToISO(this.dateSelected);
      const scheduleSelected = this.payload.schedule;
      const schedule = this.schedules.find(
        (item, i) =>
          `${i + 1}-${item.HORAINICIO}-${item.HORAFIN}` == scheduleSelected
      );
      if (!schedule) return;

      let validated = true;

      if (validation) {
        this.loadingBtn = true;
        validated = await this.validateAllBeforeSave({
          controlStock: this.ticket.CONTROLSTOCK,
          articles: this.ticket.TICKETS,
          visitDate: dateSelected,
          schedule: schedule,
          routeId: 0,
          categoryId: this.globalAforo ? 0 : this.ticket.IDCATEGORIA,
          iOption: 2,
        });
        this.loadingBtn = false;
      }

      if (!validated) {
        this.dialogCalendar = false;
        this.dialogHorario = false;
        return;
      }

      this.loadingBtn = true;
      await this.changeDateToVisit({
        ...this.ticket,
        NUEVAFECHAVISITA: dateSelected,
        HORARIOINICIO: schedule.HORAINICIO,
        HORARIOFIN: schedule.HORAFIN,
      });
      await this.loadDataPurchase(1);
      this.loadingBtn = false;
    },
    /* validateAforo(schedule, list) {
      let quantityTotal = 0;

      list.forEach((doc) => {
        quantityTotal += parseFloat(doc.CANTIDAD);
      });

      const { CANTIDAD, AFORO, ENABLED } = schedule;

      if (quantityTotal > parseFloat(CANTIDAD)) {
        this.generateSnackbarError({
          en: "The number of tickets cannot be greater than what is available",
          es: "La cantidad de entradas no puede ser superior a lo disponible",
        });
        return false;
      }
      return true;
    }, */
    validatePreLoadPurchase(purchaseId) {
      const { visitDate, user } = this.$route.query;
      if (this.user && !visitDate) {
        const founded = this.shoppingList.find(
          (item) => item.IDCOMPRA == purchaseId
        );
        if (!founded) return false;
      }
      if (!this.user && !user && !visitDate) return false;
      return true;
    },
    async loadDataPurchase(sendmail) {
      this.dialogHorario = false;
      this.dialogCalendar = false;
      this.changeDate = false;
      const { purchaseId, parkId } = this.$route.params;
      if (!purchaseId) return;
      // Valida que esté en lista si es un usuario logueado y no tiene un parámetro documento en la url
      const bool = this.validatePreLoadPurchase(purchaseId);

      if (!bool) return;

      this.$store.commit("activeLoader", true);

      const res = await this.$store.dispatch("fetchPurchaseDetail", {
        purchaseId,
        sendMail: sendmail || 2,
        parkId,
        noUser: true,
      });

      this.$store.commit("activeLoader", false);

      if (!res) {
        this.error = true;
        return;
      }

      // En caso se esté cargando desde buscar, validar si los documentos coinciden para mostrar la info

      const { visitDate, user } = this.$route.query;

      if (visitDate) {
        if (res.FECHAVISITA != "Pendiente") {
          if (visitDate != "Pendiente" && res.FECHAVISITA != visitDate) return;
        }
      }

      if (
        user &&
        String(res.CORREO).toLowerCase() != String(user).toLowerCase()
      )
        return;

      this.ticket = res;
      const articles = await this.$store.dispatch("fetchArticlesOnDateChange", {
        categoryId: this.ticket.IDCATEGORIA,
        idPark: this.ticket.IDPARQUE,
        stock: this.ticket.CONTROLSTOCK != "0" ? true : false,
        visitDate: this.ticket.FECHAVISITA,
      });
      this.articles = articles;

      this.ticket.TICKETS = this.ticket.TICKETS.map((ticket) => {
        const article = articles?.find(
          (art) => art.IDARTICULO == ticket.IDARTICULO
        );
        let valor = 1;
        if (article) {
          valor = parseFloat(article.CANTIDADVALOR);
        }
        return { ...ticket, CANTIDADVALOR: valor };
      });
      this.baseIndex += 100;
    },
    getTitle() {
      if (!this.ticket) return {};
      return this.ticket.PERMITIRGRATUITAS == 0
        ? this.titlePurchase
        : this.titleRegistry;
    },
    getImageStatusURL(notFound) {
      if (notFound) return imgService("error.png");
      return this.ticket.ESTADO == 4
        ? imgService("error.png")
        : imgService("success.png");
    },
    getClassColorStatus() {
      if (this.ticket.ESTADO == 4) return "text-danger";
      if (this.ticket.ESTADO == 2) return "text-success";
      if (this.ticket.ESTADO == 1) return "text-orange";
      return "";
    },
    getPurchaseStatusMessage() {
      if (this.ticket.ESTADO == 4) {
        return {
          es: "Tu compra no se pudo procesar",
          en: "Your purchase could not be processed",
          subtitle: {
            en:
              "Dear user, verify that you have the online shopping option active on your card.",
            es:
              "Estimado usuario, verifique que tenga activo la opción de compras en línea en su tarjeta.",
          },
        };
      } else if (this.ticket.ESTADO == 2) {
        return this.ticket.PERMITIRGRATUITAS == 0
          ? {
              en: "Your purchase was successful",
              es: "Tu compra se realizó con éxito",
            }
          : this.ticket.HABILITARSORTEO == 0
          ? {
              en: "Your registration was successful",
              es: "Tu registro se realizó con éxito",
            }
          : {
              en:
                "Your registration was successful, you are the winner of the raffle.",
              es: "Tu registro se realizó con éxito, eres ganador del sorteo.",
            };
      } else if (this.ticket.ESTADO == 1) {
        return this.ticket.HABILITARSORTEO == 0
          ? {
              en: "Your purchase is pending payment",
              es: "Tu compra esta pendiente de pago",
            }
          : {
              en:
                "Your registration was successful, wait for the results of the giveaway.",
              es:
                "Tu registro se realizó con éxito, espere los resultados del sorteo.",
            };
      }
    },
    async openDialogCalendar() {
      this.dialogCalendar = true;
      await this.loadDates();
    },

    getGeneralInformation(ticket, lang) {
      let generalInformation = [
        {
          title: {
            en: "Purchase number",
            es: "Número de compra",
          },
          value: ticket.IDCOMPRA,
          type: "text",
        },
      ];

      // Payment Data
      if (ticket.PAGOEFECTIVO == 0) {
        if (ticket.PERMITIRGRATUITAS == 0) {
          const labels = [
            {
              title: {
                en: "Date and Time",
                es: "Fecha y hora",
              },
              value: ticket.TRANSACTION_DATE,
              type: "text",
            },
            {
              title: {
                en: "Way to pay",
                es: "Forma de pago",
              },
              value: ticket.BRAND,
              type: "text",
            },
            {
              title: {
                en: "Card number",
                es: "Número de tarjeta",
              },
              value: ticket.CARD,
              type: "text",
            },
            {
              title: {
                en: "Answer",
                es: "Respuesta",
              },
              value: ticket.ACTION_DESCRIPTION,
              type: "text",
            },
          ];
          generalInformation = [...generalInformation, ...labels];
        }
      } else {
        if (ticket.ESTADO == 1) {
          const labels = [
            {
              title: {
                en: "Payment Code (CIP)",
                es: "Código de pago (CIP)",
              },
              value: ticket.URLPAGOEFECTIVO,
              linkTitle: {
                en: "Enter here",
                es: "Ingrese aquí",
              },
              type: "link",
            },
          ];
          generalInformation = [...generalInformation, ...labels];
        }
      }

      // Purchase info
      if (ticket.PERMITIRGRATUITAS == 0) {
        generalInformation.push({
          // Title
          title: {
            en: "Purchase information",
            es: "Información de la compra",
          },
          type: "title",
        });
      } else {
        generalInformation.push({
          // Title
          title: {
            en: "Registry information",
            es: "Información del registro",
          },
          type: "title",
        });
      }

      generalInformation.push({
        // Email
        title: {
          en: "E-mail",
          es: "Correo electrónico",
        },
        value: ticket.CORREO,
        type: "text",
      });

      if (ticket.NUMERODOCUMENTO != "") {
        generalInformation.push({
          // Document number
          title: {
            en: "Document number",
            es: "Número de documento",
          },
          value: `${ticket.NUMERODOCUMENTO} - (${ticket.TIPODOCUMENTO})`,
          type: "text",
        });
      }

      generalInformation.push({
        // last name
        title: {
          en: "Last name",
          es: "Apellidos",
        },
        value: ticket.APELLIDOS,
        type: "text",
      });
      generalInformation.push({
        // names
        title: {
          en: "Last name",
          es: "Nombres",
        },
        value: ticket.NOMBRES,
        type: "text",
      });

      if (ticket.CAMBIOFECHA == 1 && ticket.ESTADO == 2) {
        generalInformation.push({
          // Day of visit
          title: {
            en: "Day of visit",
            es: "Fecha de visita",
          },
          value: `<button class="d-flex align-items-center text-success" type="button" id="btnOpenCalendar"><span class="material-icons mr-2">event</span> ${ticket.FECHAVISITA}</button>`,
          type: "html",
        });
        this.changeDate = true;
        if (ticket.SINFECHA == 1) {
          if (ticket.HORARIO != 0) {
            generalInformation.push({
              // Schedule
              title: {
                en: `${ticket.GRUPOCATEGORIA}`,
                es: `${ticket.GRUPOCATEGORIA}`,
              },
              value: ticket.HORAINICIO,
              type: "text",
            });
          }
        } else {
          if (ticket.HORARIO != 0) {
            generalInformation.push({
              // Schedule
              title: {
                en: `${ticket.GRUPOCATEGORIA}`,
                es: `${ticket.GRUPOCATEGORIA}`,
              },
              value: ticket?.HORARIO_DESCRIPCION
                ? ticket.HORARIO_DESCRIPCION
                : `${ticket.HORAINICIO} - ${ticket.HORAFIN}`,
              type: "text",
            });
          }
        }

        if (ticket.RUTA != 0) {
          generalInformation.push({
            // Schedule
            title: {
              en: `Visit rout`,
              es: `Ruta de visita`,
            },
            value: ticket.RUTADESCRIPCION,
            type: "text",
          });
          generalInformation.push({
            // Schedule
            title: {
              en: ``,
              es: ``,
            },
            value: ticket.RUTADESCRIPCIONCORTA,
            type: "text",
          });
        }
      } else {
        if (
          ticket.HABILITARCOMPRAANTICIPADA == 1 &&
          ticket.HABILITARCAMBIOFECHA == 0
        ) {
          //
        } else {
          generalInformation.push({
            // Schedule
            title: {
              en: `Day of visit`,
              es: `Fecha de visita`,
            },
            value: ticket.FECHAVISITA,
            type: "text",
          });
          if (ticket.HORARIO != 0) {
            generalInformation.push({
              // Schedule
              title: {
                en: `${ticket.GRUPOCATEGORIA}`,
                es: `${ticket.GRUPOCATEGORIA}`,
              },
              value: ticket?.HORARIO_DESCRIPCION
                ? ticket.HORARIO_DESCRIPCION
                : `${ticket.HORAINICIO} - ${ticket.HORAFIN}`,
              type: "text",
            });
          }
          if (ticket.RUTA != 0) {
            generalInformation.push({
              // Schedule
              title: {
                en: `Visit rout`,
                es: `Ruta de visita`,
              },
              value: ticket.RUTADESCRIPCION,
              type: "text",
            });
            generalInformation.push({
              // Schedule
              title: {
                en: ``,
                es: ``,
              },
              value: ticket.RUTADESCRIPCIONCORTA,
              type: "text",
            });
          }
        }
      }

      generalInformation.push({
        // total
        title: {
          en: `Total`,
          es: `Total`,
        },
        value: `S/ ${ticket.TOTAL}`,
        type: "text",
      });

      // Acompaniantes
      if (
        ticket.PERMITIRGRATUITAS == 1 &&
        ticket.ACOMPANIANTES &&
        ticket.ACOMPANIANTES.length > 0
      ) {
        generalInformation.push({
          // title
          title: {
            en: `Identification of companions`,
            es: `Identificación de acompañantes`,
          },
          type: "title",
        });

        generalInformation.push({
          // acompaniantes list
          title: {
            en: ``,
            es: ``,
          },
          value: `<div class="list-acompaniantes">
                    ${ticket.ACOMPANIANTES.map((ac) => {
                      return `
                        <div class="acompaniante">
                          <span>${ac.NUMERODOCUMENTO}</span>
                          <span>${
                            ac.TIPODOCUMENTO == 1
                              ? lang == "es"
                                ? "(DNI)"
                                : "(DNI)"
                              : lang == "es"
                              ? "(Pasaporte / CE)"
                              : "(Pasaport / IC)"
                          }</span>
                        </div>
                      `;
                    })}
                  </div>`,
          type: "html",
        });
      }

      // Facturation
      if (ticket.TIPOCOMPROBANTE == "01") {
        generalInformation.push({
          // title
          title: {
            en: `Billing`,
            es: `Facturación`,
          },
          type: "title",
        });
        generalInformation.push({
          // RUC
          title: {
            en: `RUC`,
            es: `RUC`,
          },
          value: ticket.RUC,
          type: "text",
        });
        generalInformation.push({
          // RAZONSOCIAL
          title: {
            en: `Business name`,
            es: `Razón social`,
          },
          value: ticket.RAZONSOCIAL,
          type: "text",
        });
        generalInformation.push({
          // DIRECCION
          title: {
            en: `Direction`,
            es: `Dirección`,
          },
          value: ticket.DIRECCION,
          type: "text",
        });
        generalInformation.push({
          // DEPARTAMENTO
          title: {
            en: `Departament`,
            es: `Departamento`,
          },
          value: ticket.DEPARTAMENTO,
          type: "text",
        });
        generalInformation.push({
          // PROVINCIA
          title: {
            en: `Province`,
            es: `Provincia`,
          },
          value: ticket.PROVINCIA,
          type: "text",
        });
        generalInformation.push({
          // DISTRITO
          title: {
            en: `Distric`,
            es: `Distrito`,
          },
          value: ticket.DISTRITO,
          type: "text",
        });
      }

      // Detalle registro/compra
      if (ticket.PERMITIRGRATUITAS == 0) {
        generalInformation.push({
          // title
          title: {
            en: `Detail of the purchase`,
            es: `Detalle de la compra`,
          },
          type: "title",
        });
      } else {
        generalInformation.push({
          // title
          title: {
            en: `Purchase details`,
            es: `Resumen del registro`,
          },
          type: "title",
        });
      }

      generalInformation.push({
        // title table
        title: {
          en: `${ticket.DESCRIPCIONCATEGORIA}`,
          es: `${ticket.DESCRIPCIONCATEGORIA}`,
        },
        type: "chip-title",
      });

      generalInformation.push({
        // Table resume
        title: {
          en: ``,
          es: ``,
        },
        value: `
          <div class="section-container">
            <table class="resume-table">
              ${ticket.TICKETS.map((item) => {
                return `
                  <tr class="readable">
                    <td align="center" valign="top">${item.CANTIDAD}</td>
                    <td align="center" valign="top">X</td>
                    <td align="center" valign="top" class="number">S/ ${item.PRECIO}</td>
                    <td align="left" valign="top">${item.ARTICULO_DESCRIPCION_CORTA}</td>
                    <td align="center" valign="top" class="number">S/ ${item.SUBTOTAL}</td>
                  </tr>

                  <tr>
                    <td colspan="3"></td>
                    <td colspan="2">
                      <span class="description-article pre-wrapp">${item.ARTICULO_DESCRIPCION_LARGA}</span>
                    </td>
                  </tr>
                `;
              }).join("")}
            </table>
          </div>
        `,
        type: "section",
      });

      if (ticket.PASADO == 1 && ticket.ESTADO == 2 && ticket.VALIDADO == 0) {
        generalInformation.push({
          // QR Title
          title: {
            en: `Validation code`,
            es: `Código de validación`,
          },
          value: ``,
          type: "title",
        });
        if (!ticket.VALIDACIONINDIVIDUAL || ticket.VALIDACIONINDIVIDUAL == 0) {
          generalInformation.push({
            // QR
            title: {
              en: ``,
              es: ``,
            },
            value: `
              <div class="section-container d-flex flex-column align-items-center">
                <div class="text-medium fw-600 text-center">${ticket.PARQUE_DESCRIPCION}</div>
                <img draggable="false" src="https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${ticket.IDSISTEMA}-${ticket.IDCOMPRA}&choe=UTF-8">
                <div class="text-medium fw-600 text-center">${ticket.IDSISTEMA}-${ticket.IDCOMPRA}</div>
                <div class="text-medium fw-600 text-center mt-4 mb-4">${ticket.MENSAJERESUMEN}</div>
              </div>
            `,
            type: "section",
          });
        } else if (ticket.VALIDACIONINDIVIDUAL == 1) {
          for (const boleto of ticket?.boletos) {
            const labels = {
              zona: {
                en: "Zone",
                es: "Zona",
              },
              butaca: {
                en: "Seat",
                es: "Butaca",
              },
              foro: {
                en: "Place",
                es: "Lugar",
              },
              visitDate: {
                en: "Visit Date",
                es: "Fecha visita",
              },
              informationTicket: {
                en: "Ticket information",
                es: "Información del boleto",
              },
              product: {
                en: "Concept",
                es: "Concepto",
              },
            };
            generalInformation.push({
              // QR
              title: {
                en: ``,
                es: ``,
              },
              value: `
                <div class="card__ticket_item section-container d-flex flex-column align-items-center">
                  <div class="text-medium fw-600 text-center w-100 py-3" style="border-bottom: 1px solid rgba(0,0,0,.1);">
                    <p class="mb-0" style="line-height: 1.2; border-radius: 15px;height:auto;padding:0.5em;display:inline-block; color: #636363 !important;">
                      <b style="margin:1em">
                        ${ticket.DESCRIPCIONCATEGORIA}
                        <br />
                        Nº ${boleto.IDSISTEMA}-${boleto.IDCOMPRA}-${
                boleto.IDBOLETO
              }
                      </b>
                    </p>
                  </div>
                  <div class="card__ticket_item_container">
                    <div class="card__ticket_item_details">
                      ${
                        ticket.HABILITARCOMPRAANTICIPADA != 1 &&
                        ticket.HABILITARCAMBIOFECHA != 0
                          ? `
                            <b>${labels.visitDate[lang]}:</b> ${
                              ticket.FECHAVISITA
                            } <br/>
                            ${
                              ticket.HORARIO > 0
                                ? `
                                  <b>${ticket.GRUPOCATEGORIA}:</b> ${ticket.HORARIO_DESCRIPCION} <br/>
                                `
                                : ``
                            }
                          `
                          : ``
                      }
                      <h2 style="color:#00aca0;display:block;font-family:Helvetica,Roboto,Arial,sans-serif;font-weight:bold;margin: 1em 1em 1em 0;text-align:left; font-size: 15px;">
                        <span style=" border-bottom: 1px solid #00aca0; padding-bottom: 3px;">
                          ${labels.informationTicket[lang]}
                        </span>
                      </h2>

                      <b>${labels.product[lang]}:</b> ${
                boleto.ARTICULO_DESCRIPCION_CORTA
              } <br/>
                      ${
                        boleto?.ZONA !== ""
                          ? `
                          <b>${labels.zona[lang]}:</b> ${boleto.ZONA} <br/>
                        `
                          : ""
                      }
                      ${
                        boleto?.BUTACA !== ""
                          ? `
                          <b>${labels.butaca[lang]}:</b> ${boleto.BUTACA} <br/>
                        `
                          : ""
                      }
                      ${
                        boleto?.FORO != ""
                          ? `
                          <b>${labels.foro[lang]}:</b> ${boleto.FORO} <br/>
                        `
                          : ""
                      }
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <h4><b>${ticket.PARQUE_DESCRIPCION}</b></h4>
                      <img draggable="false" src="https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${
                        ticket.IDSISTEMA
                      }-${ticket.IDCOMPRA}-${boleto.IDBOLETO}&choe=UTF-8">
                      <div class="text-medium fw-600 text-center">${
                        ticket.IDSISTEMA
                      }-${ticket.IDCOMPRA}-${boleto.IDBOLETO}</div>
                      <div class="text-medium fw-600 text-center mt-4 mb-4">${
                        ticket.MENSAJERESUMEN
                      }</div>
                    </div>
                  </div>
                </div>
              `,
              type: "section",
            });
          }
        }
      }

      generalInformation.push({
        title: {
            en: ``,
            es: ``,
          },
          value: `
            <div class="section-container" style="text-align:center;vertical-align:middle;border:0px solid #eee;color:#636363;color:#636363 !important;padding:8px">
              ${ticket.MENSAJERESUMEN}
            </div>
          `,
          type: "section",
      })

      return generalInformation;
    },
    async getPurchasePDF() {
      const { purchaseId } = this.$route.params;
      if (!purchaseId) return;
      this.loadingPDF = true;
      await this.$store.dispatch("downloadPurchaseFile", {
        idPdf: this.ticket.PDF,
        idPark: this.ticket.IDPARQUE,
        idCompra: this.ticket.IDCOMPRA,
      });
      this.loadingPDF = false;
    },
  },
};
</script>

<style lang="scss">
.aforo-info {
  display: flex;
  span {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    min-width: 50px;
    max-width: 50px;
    text-align: center;
  }
  span.divider {
    max-width: 10px;
    display: flex;
    justify-content: center;
  }
  span.title-type {
    font-size: 8px;
  }
}

.text-detail {
  opacity: 1;
  color: #444;
  display: flex;
  gap: 10px;
  & > * {
    flex: 1;
  }
  strong {
    max-width: 200px;
  }
  span {
    opacity: 0.85;
    font-weight: 400;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.subtitle-ticket {
  position: relative;
  display: inline-block;
  color: var(--color-pink) !important;
  &::before {
    content: "";
    position: absolute;
    top: calc(100% + 3px);
    height: 3px;
    background: var(--color-pink);
    width: 100%;
  }
}

.text-danger {
  color: var(--color-danger);
}

.text-orange {
  color: var(--color-yellow);
}

.text-success {
  color: var(--color-turquoise);
}

.text-blue {
  color: var(--color-blue);
}

.list-acompaniantes {
  display: inline-flex;
  flex-flow: column nowrap;
  .acompaniante {
    display: inline-flex;
    background: var(--color-blue);
    margin-bottom: 4px;
    gap: 4px;
    color: #fff;
    padding: 3px 6px;
    border-radius: 3px;
  }
}

.section-detail {
  .section-container {
    max-width: 700px;
    margin: 0 auto;
  }
}

.section-title {
  display: flex;
  justify-content: center;
  h4 {
    padding: 8px 15px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 13px;
    color: #fff;
    background: var(--color-blue);
  }
}

.resume-table {
  opacity: 1 !important;
  width: 100%;
  td {
    padding: 4px;
    font-size: 13px;
    color: #444;
  }
  td.number {
    min-width: 70px;
  }
  .description-article {
    font-size: 11px;
  }
}

.card__ticket_item {
  /* box-shadow: 3px 4px 5px 7px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 3px 4px 5px 7px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 4px 5px 7px rgba(0, 0, 0, 0.12); */
  border: 1px solid #e5e5e5;
  // padding-top: 15px;
  margin-bottom: 15px !important;
  &_container {
    padding: 1em 2em 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    width: 100%;
    b {
      color: #444;
    }
    @media screen and (max-width: 700px) {
      display: flex;
      flex-flow: column-reverse nowrap;
      padding-bottom: 1em;
    }
  }
  &_details {
    @media screen and (max-width: 600px) {
      margin-bottom: 15px !important;
      // padding: 0 30px 30px !important;
    }
  }
}
</style>
